import React, { useState, useMemo, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Label, FormGroup, Nav, NavItem, NavLink, Table } from "reactstrap";
import { LabelText } from "../Utils/Input";
import PieChart from "./constants/PieChart";
import { CustomerAddress, CustomerViewDetails } from "./CustomerViewDetail";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import SelectField from "../Utils/SelectField";
import classnames from 'classnames';
import AggregateResourceCarousel from "./AggregateResource";

export const CustomerProfile = ({ customerData, fetchCustomerSummaryData, formatDate, customerPermission, resourceData }) => {
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [activeTabForAddOn, setActiveTabForAddOn] = useState("1");
    const [addOnLength, setAddOnLength] = useState(0);
    const [baseOfferLength, setBaseOfferLength] = useState(0);
    const roleName = localStorage.getItem('roleName')
     const aggregateResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAggregate);
    const tabChange = (tab) => {
        if (activeTabForAddOn !== tab) setActiveTabForAddOn(tab);
    };

    const groupedResources = useMemo(() => {
        return fetchCustomerSummaryData?.resources?.reduce((acc, resource) => {
            const key = `${resource.subscriptionId}-${resource.offerCode}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(resource);
            return acc;
        }, {});
    }, [fetchCustomerSummaryData]);

    const handleSubscriptionChange = (selectedOption) => {
        setSelectedSubscriptionId(selectedOption ? selectedOption.value : "");
        setActiveIndex(0);
    };

    const uniqueSubscriptionIds = useMemo(() => {
        return [...new Set(
            fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAggregate && resource.subscriptionId)?.map(resource => resource.subscriptionId)
        )];
    }, [fetchCustomerSummaryData]);
    useEffect(() => {
        const addOns = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn) || [];
        const baseOffers = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn) || [];
        setAddOnLength(addOns.length || 0);
        setBaseOfferLength(baseOffers.length || 0);
    }, [fetchCustomerSummaryData?.resources]);
    useEffect(() => {
        if (uniqueSubscriptionIds.length > 0 && !selectedSubscriptionId) {
            setSelectedSubscriptionId(uniqueSubscriptionIds[0]);
        }
    }, [uniqueSubscriptionIds]);

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const next = (resources) => {
        const nextIndex = activeIndex === resources.length - 1 ? 0 : activeIndex + 1;
        goToIndex(nextIndex);
    };

    const previous = (resources) => {
        const prevIndex = activeIndex === 0 ? resources.length - 1 : activeIndex - 1;
        goToIndex(prevIndex);
    };

    const renderGrantsCarousel = (resources) => {
        const slides = resources.map((resource) => (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={resource.resourceName}
            >
                <Card className="border border-2 customer-view-screen">
                    <CardHeader className="d-flex align-items-center customer-view-screen">
                        <Label className="fs-5 mt-2">Grant Bucket</Label>
                    </CardHeader>
                    <CardBody className="px-5">
                        <div className="d-flex flex-column align-items-center flex-md-row">
                            <PieChart
                                remainingBalance={resource.remainingBalance}
                                originalBalance={resource.originalBalance}
                                width={"100%"}
                                height={200}
                            />
                            <div>
                                <p className="fw-bolder fs-5">{resource.resourceName}</p>
                                <p>
                                    <b>{resource.remainingBalance}</b> left of <b>{resource.originalBalance}</b>
                                </p>
                                <p className="d-flex justify-content-start">
                                    <LabelText displayText={`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`} />
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </CarouselItem>
        ));

        return (
            <Carousel activeIndex={activeIndex} next={() => next(resources)} previous={() => previous(resources)} interval={false}>
                <CarouselIndicators items={resources} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous(resources)} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={() => next(resources)} />
            </Carousel>
        );
    };
    const calculateAggregateBalance = (subscriptionId) => {
        const aggregateResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.subscriptionId === subscriptionId && resource.isAggregate);
        const totalBalance = aggregateResources?.reduce((total, resource) => total + parseFloat(resource?.consumedBalance || 0), 0);
        const unitOfMeasure = aggregateResources?.length > 0 ? aggregateResources[0]?.uom : "N/A"; // assuming all aggregated resources share the same UoM

        return { totalBalance, unitOfMeasure };
    };

    const { totalBalance: aggregateBalance, unitOfMeasure: aggregateUoM } = useMemo(() => calculateAggregateBalance(selectedSubscriptionId), [selectedSubscriptionId, fetchCustomerSummaryData]);
    return (
        <Row lg={12}>
            {roleName !== 'Customer' && (
                <>
                    <CustomerViewDetails customerData={customerData} customerPermission={customerPermission} aggregateResources={aggregateResources} />
                    <CustomerAddress customerData={customerData} aggregateResources={aggregateResources}/>
                </>
            )}
            {aggregateResources?.length>0 && roleName!=='Customer'?
            <Col lg={3}>
                <Card className="border border-2">
                <header className="mb-4 p-1 ms-3 ">
                    <h2 className="fs-5 mt-4 fw-medium balance-heading-color">Aggregate View</h2>
                </header>
                <div className=" mb-2">
                <AggregateResourceCarousel resources={aggregateResources} />
                </div>
                </Card>
            </Col>:null}
            <Col lg={12}>
                <div className="border-bottom mt-n4" />
                {groupedResources && (
                    <Card className="border border-2">
                        <CardHeader className="d-flex align-items-center">
                            <Label className="fs-5 mt-2 balance-heading-color">Subscriptions</Label>
                        </CardHeader>
                        <Row>

                            <Col xxl={4} lg={4} md={4} xs={11} className="ms-3 mt-2 ">
                                <FormGroup>
                                    <Label for="subscriptionSelect" className="fw-bold">Subscription ID</Label>
                                    <SelectField
                                        id="subscriptionSelect"
                                        value={selectedSubscriptionId ? { value: selectedSubscriptionId, label: selectedSubscriptionId } : null}
                                        placeholder="Select Subscription ID"
                                        handleChange={handleSubscriptionChange}
                                        options={uniqueSubscriptionIds.map(id => ({ value: id, label: id }))}

                                        isMulti={false}
                                    />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-top border-bottom mt-2  mb-3" role="tablist">
                            <div className="ms-3 d-flex justify-content-between">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTabForAddOn === "1" })}
                                        onClick={() => tabChange("1")}
                                    >
                                        Base Offer
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTabForAddOn === "2" })}
                                        onClick={() => tabChange("2")}
                                    >
                                        Add-On Offer
                                    </NavLink>
                                </NavItem></div>
                        </Nav>
                        {selectedSubscriptionId &&
                            Object.keys(groupedResources)?.filter(key => key.startsWith(selectedSubscriptionId)).map((key, index) => {
                                const resources = groupedResources[key];
                                const filteredResources = resources?.filter(resource =>
                                    (activeTabForAddOn === "1" && !resource.isAddOn) ||
                                    (activeTabForAddOn === "2" && resource.isAddOn)
                                );
                            
                                return (
                                    <CardBody key={index} className="card-body">
                                        {filteredResources.length > 0 ? (
                                            <Row className="gy-4">
                                                <Col lg={6}>
                                                    {filteredResources.length > 1 ? renderGrantsCarousel(filteredResources) : (
                                                        <Card className="border border-2 customer-view-screen">
                                                            <CardHeader className="d-flex align-items-center customer-view-screen">
                                                                <Label className="fs-5 mt-2">Grant</Label>
                                                            </CardHeader>
                                                            <CardBody className="px-5">
                                                                <div className="d-flex flex-column align-items-center flex-md-row">
                                                                    <PieChart
                                                                        remainingBalance={filteredResources[0].remainingBalance}
                                                                        originalBalance={filteredResources[0].originalBalance}
                                                                        width={"100%"}
                                                                        height={200}
                                                                    />
                                                                    <div>
                                                                        <p className="fw-bolder fs-5">{filteredResources[0].resourceName}</p>
                                                                        <p>
                                                                            <b>{filteredResources[0].remainingBalance}</b> left of <b>{filteredResources[0].originalBalance}</b>
                                                                        </p>
                                                                        <p className="d-flex justify-content-start">
                                                                            <LabelText displayText={`${formatDate(filteredResources[0].validFrom)} - ${formatDate(filteredResources[0].validTo)}`} />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </Col>
                                                <Col lg={6}>
                                                    {filteredResources && (
                                                        <Card className="border border-2" style={{ height: "288px" }}>
                                                            <CardHeader className="d-flex align-items-center">
                                                                <h5 className="fs-5 mb-0">Usage</h5>
                                                            </CardHeader>
                                                            <div className="table-responsive">
                                                                <Table className="table table-borderless table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="p-3" scope="col">Name</th>
                                                                            <th className="p-3" scope="col">Value</th>
                                                                            <th className="p-3" scope="col">Units</th>
                                                                            <th className="p-3" scope="col">Time Period</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {resources?.map((resource, idx) => (
                                                                            <tr key={idx}> 
                                                                                <td className="p-3">{resource?.resourceName}</td>
                                                                                <td className="p-3 ">{(resource?.consumedBalance)}</td>
                                                                                <td className="p-3">{resource?.uom}</td>
                                                                                <td>{`${formatDate(resource?.validFrom)} - ${formatDate(resource?.validTo)}`}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card>
                                                        
                                                    )}
                                                </Col>
                                               
                                            </Row>
                                        ) : (
                                            addOnLength<=0 && baseOfferLength>0 &&
                                            (
                                                <p className="d-flex justify-content-center fw-bold fs-5 balance-heading-color">
                                                    No Add-Ons purchased for this subscription
                                                </p>
                                            )
                                        )}
                                    </CardBody>
                                );
                            })
                        }
                    </Card>
                )}
            </Col>
        </Row>
    );
};

export default CustomerProfile;
